import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function FeedbackAPIDocs() {
  const json = {
  "data": [
    {
      "id": "6699e11db7c5974d1f74f850",
      "acceptanceStatus": "accepted",
      "timestamp": "2024-07-16T13:56:42.575Z",
      "type": "similar_cases",
      "similarTicketId": "236",
      "sourceUserId": "421221152",
      "ticketId": "2397"
    },
    {
      "id": "6699e11db7c5954d1f74f822",
      "acceptanceStatus": "accepted",
      "timestamp": "2024-07-16T13:56:37.833Z",
      "type": "resolution",
      "similarTicketId": "",
      "sourceUserId": "93221152",
      "ticketId": "232507"
    },
    {
      "id": "6699e11db7c5974d1f74f822",
      "acceptanceStatus": "ignored",
      "timestamp": "2024-07-16T13:56:41.212Z",
      "type": "suggested_jira",
      "similarTicketId": "",
      "sourceUserId": "421391152",
      "ticketId": "2397"
    },
    {
      "id": "6699e11db7c5934d1f74f822",
      "acceptanceStatus": "accepted",
      "timestamp": "2024-07-16T13:56:39.883Z",
      "type": "similar_cases",
      "similarTicketId": "43620",
      "sourceUserId": "4211152",
      "ticketId": "2397"
    },
    {
      "id": "6699e11d44c5974d1f74f822",
      "acceptanceStatus": "ignored",
      "timestamp": "2024-07-16T13:56:38.545Z",
      "type": "similar_cases",
      "similarTicketId": "251755",
      "sourceUserId": "493221152",
      "ticketId": "23507"
    }
  ]
}

  return (
    <Layout
      keywords="IrisAgent Feedback API guide"
      title="Use IrisAgent's Feedback API to export user feedback on AI insights"
      description="Documentation on how to use IrisAgent's Feedback API to export feedback upvotes and downvotes on Gen-AI powered insights"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/feedback-api-docs/"
        />
      </Helmet>
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
            <br/><br/><br/>
                    <h1>IrisAgent Feedback API Documentation</h1>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Here is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our feedback API:
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`
curl --location \\
'https://frontend-api-server-v2.api.irisagent.com/v1/reports/feedback?from=2024-07-01&to=2024-07-04&limit=10' \\
--header 'X-Business-Id: REPLACE_ME_BUSINESS_ID'
  `}
</SyntaxHighlighter>
<br/>
Here, the inputs are:
<br/><br/>
<ul>
    <li>
    <span style={{"font-family": "Consolas"}}>from</span>: [Required] URL-encoded starting time string, e.g. 2021-01-01. This will match the time field of when the feedback vote was provided.
    </li>
    <li>
    <span style={{"font-family": "Consolas"}}>to</span>: [Required] URL-encoded ending time string, e.g. 2021-01-01. This will match the time field of when the feedback vote was provided.
    </li>
    <li>
    <span style={{"font-family": "Consolas"}}>limit</span>: [Optional] Number of results to return. The default value is 1000.
    </li>
    <li>
    <span style={{"font-family": "Consolas"}}>REPLACE_ME_BUSINESS_ID</span>: This is the ID provided by the IrisAgent team
    </li>
</ul>
<br/>
Below is a sample output of the API:
<br/>
<SyntaxHighlighter language="javascript" style={prism}>
{JSON.stringify(json, null, 2)}
</SyntaxHighlighter>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}